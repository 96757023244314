/**
 * Created by zj on 2021-11-09.
 */
const _URL_ADDRESS = '';
const _GATEWAY_GW2 = '/gw2';//网关2
const _GATEWAY_GW3 = '/gw3';//网关2

/**
 * 统一按照ZUUL.yml中的配置进行定义
 */
/** 认证授权（登录使用） */
const _PROJECT_AUTH = '/platform/auth';
/** 服务接口（企业部分） */
const _PROJECT_MANAGER = '/platform/manager';
// 集成
const _PROJECT_JICHENG = '/platform/jicheng';

const _SERVICE_AUTH = _URL_ADDRESS + _GATEWAY_GW2 + _PROJECT_AUTH; // 认证授权（登录使用）
const _SERVICE_MANAGER = _URL_ADDRESS + _GATEWAY_GW2 + _PROJECT_MANAGER; // 服务接口（企业部分）
const _SERVICE_JICHENG = _URL_ADDRESS + _GATEWAY_GW3 + _PROJECT_JICHENG; // 集成接口（企业部分）

export default {
    MANAGER_GETUSERINFO: _SERVICE_AUTH + '/getUserInfo', // 获取当前登录用户信息
    MANAGER_APILOGIN: _SERVICE_AUTH + '/api/login', // 登录
    MANAGER_APIREGISTERUSER: _SERVICE_AUTH + '/api/registerUser', // 注册
    MANAGER_LOGOUT: _SERVICE_AUTH + '/logout', // 注销退出
    MANAGER_APIGETLOGININFO: _SERVICE_AUTH + '/api/getLoginInfo', // 查询登录信息
    MANAGER_RESOURCESLOADMENU: _SERVICE_AUTH + '/resources/loadMenu', // 获取权限信息（包括菜单）
    MANAGER_APIQUERYENTERPRISELISTBYPHONE: _SERVICE_AUTH + '/api/queryEnterpriseListByPhone', // 获取企业列表
    MANAGER_CHANGEERPRISE: _SERVICE_AUTH + '/changeLoginUserEnterprise', // 切换企业列表
    
    MANAGER_BIPAUTH: _SERVICE_JICHENG + '/api/bip/auth', // 单点登录认证 
    MANAGER_BIPBIND: _SERVICE_JICHENG + '/api/bip/bind', // 单点登录绑定企业

    
    MANAGER_SENDSMSCODE: _SERVICE_AUTH + '/api/sendSmsVerifyCode', //发送短信验证码
    MANAGER_CHECKSENDSMSCODE: _SERVICE_AUTH + '/api/checkSmsVerifyCode', //验证短信验证码
    MANAGER_GETCAPTCHA: _SERVICE_AUTH + '/api/getCaptcha', //生成图片验证码
    MANAGER_CHECKCAPTCHA: _SERVICE_AUTH + '/api/checkCaptcha', //验证图片验证码
    
    MANAGER_RESETPWD: _SERVICE_AUTH + '/api/resetPassword', //重置密码
    MANAGER_CHECKPHONE: _SERVICE_AUTH + '/api/user/checkPhone', //找回密码发送验证码前，验证号码是否有效
    MANAGER_APIENTERPRISECHECKNAME: _SERVICE_MANAGER + '/api/enterprise/checkName', // 平台内企业名称唯一性校验
    MANAGER_APICONSULT: _SERVICE_MANAGER + '/api/portal/businessConsulting/save', // 业务咨询
    MANAGER_APICONSULT_419: _SERVICE_MANAGER + '/api/portal/businessConsulting/419', // 业务咨询-活动
    MANAGER_APICONSULT_819: _SERVICE_MANAGER + '/api/portal/businessConsulting/819', // 业务咨询-活动



    MANAGER_GETRSAPUBLICKEY: _SERVICE_AUTH + '/api/getRsaPublicKey', //查询RSA公钥


    MANAGER_GETLOGINLOGO: _SERVICE_MANAGER + '/api/enterpriseDefine/findLoginLogoByDomain', //查询RSA公钥
}