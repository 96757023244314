const defaultLogo = require('../../assets/images/home-logo.png')

const state = {
    loginLogoUrl: defaultLogo
}

const mutations = {
    SET_LOGO (state, logoUrl) {
        if (logoUrl) {
            state.loginLogoUrl = logoUrl
        }
    },
}

export default {
    namespaced: true,
    state,
    mutations
}
