
import { GETLOGININFO } from '@/apis/auth'


/*
interface loginInfo = {
  hasLogin: true
  userName: "5G直客"
}
*/

const state = {
  loginInfo: null,
  hasLogin: false,
}

// actions
const actions = {
  getLoginInfo ({ commit }) {
    GETLOGININFO().then((response) =>{
			// console.log('############ GETLOGININFO 成功 ############', response.data.data)
			if(response.data.data.hasLogin) {
        commit('SET_LOGIN_INFO', response.data.data)
        commit('HAS_LOGIN', true)
			} else {
        commit('HAS_LOGIN', false)
      }
		}).catch(function(error){
			console.log('############ GETLOGININFO 失败 ############', error);
		});
  },
  logout ({ commit }) {
    commit('SET_LOGIN_INFO', null)
    commit('HAS_LOGIN', false)
  },
}

// mutations
const mutations = {
  SET_LOGIN_INFO (state, loginInfo) {
    // console.log('mutations setLoginInfo #####', JSON.parse(JSON.stringify(state.loginInfo)), loginInfo)
    state.loginInfo = Object.assign([], state.loginInfo, loginInfo);
  },
  HAS_LOGIN (state, hasLogin) {
    state.hasLogin = hasLogin
  }

}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
