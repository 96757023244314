


import req from './axios'
import URL from './url'

//定义接口 例子post，参数params，data（body+json），
// export const 定义ajax暴露的方法名 = ({params, data}) => req('post', URL里定义的api地址, params, data)

export const GETUSERINFO = () => req('get', URL.MANAGER_GETUSERINFO) // 获取登录信息
export const GETLOGININFO = () => req('get', URL.MANAGER_APIGETLOGININFO) // 查询登录信息
export const LOADMENU = params => req('get', URL.MANAGER_RESOURCESLOADMENU, params) // 获取权限信息（包括菜单）

