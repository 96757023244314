import axios from 'axios'
 
//创建axios的一个实例 
var instance = axios.create({
    baseURL: '',
    withCredentials: false, // default
    responseType: 'json', // default
    validateStatus: function (status) {
        return status >= 200 && status < 300; // default
    },
    maxRedirects: 5, // default
    timeout: 30000
})
 
instance.defaults.headers.post['Content-Type'] =
'application/json' 
//------------------- 一、请求拦截器 忽略
instance.interceptors.request.use(function (config) {
    // console.log('############ 请求拦截器 忽略 ############', config);
    return config;
}, function (error) {
    console.log('############ 请求拦截器 报错 ############', error);
    return Promise.reject(error);
});
// var urlConfig = {
//     baseURL: '',
//     headers: {'X-Requested-With': 'XMLHttpRequest'},
//     withCredentials: false, // default
//     responseType: 'json', // default
//     validateStatus: function (status) {
//         return status >= 200 && status < 300; // default
//     },
//     maxRedirects: 5, // default
// };
// instance.interceptors.request.use(urlConfig);
 
//----------------- 二、响应拦截器 忽略
// 拦截响应response，并做一些错误处理
instance.interceptors.response.use(function (response) {
    // console.log('#响应拦截器 忽略#', response);
    return response;
}, function (err) { // 这里是返回状态码不为200时候的错误处理
    if (err && err.response) {
        // console.log('############ 状态码不为200 ############', JSON.stringify(err.response))
        switch (err.response.status) {
            case 400:
                err.message = '请求错误'
                break

            case 401:
                err.message = '未授权，请登录';
                debugger
                window.top.location.href = '/login';
                break

            case 403:
                err.message = '拒绝访问'
                break

            case 404:
                err.message = '请求地址出错: ' + err.response.config.url;
                break

            case 408:
                err.message = '请求超时'
                break

            case 500:
                err.message = '服务器内部错误'
                break

            case 501:
                err.message = '服务未实现'
                break

            case 502:
                err.message = '网关错误'
                break

            case 503:
                err.message = '服务不可用'
                break

            case 504:
                err.message = '网关超时'
                break

            case 505:
                err.message = 'HTTP版本不受支持'
                break

            default:
        }
    }
    // console.log('############ 响应拦截器 拦截器报错 ############', err);
    return Promise.reject(err)
})
 
/**
 * 使用es6的export default导出了一个函数，导出的函数代替axios去帮我们请求数据，
 * 函数的参数及返回值如下：
 * @param {String} method  请求的方法：get、post、delete、put
 * @param {String} url     请求的url:
 * @param {Object} params  请求的参数，拼在url后面的
 * @param {Object} data    请求的参数，body+json
 * @returns {Promise}      返回一个promise对象，其实就相当于axios请求数据的返回值
 */
export default function (method, url, params = null, data = null) {   
    method = method.toLowerCase();
    if (method == 'login') {
        return instance({headers: {}, method: 'post', url, params })
    } else if (method == 'post') {
        return instance({ headers: {'X-Requested-With': 'XMLHttpRequest'}, method, url, params, data })
    } else if (method == 'get') {
        return instance({ headers: {'X-Requested-With': 'XMLHttpRequest'}, method, url, params  })
    } else if (method == 'delete') { // 没用过
        return instance.delete(url, params)
    }else if(method == 'put'){ // 没用过
        return instance.put(url, data)
    }else if(method == 'img'){ 
        return instance({ headers: {'X-Requested-With': 'XMLHttpRequest'}, method, url, params, data, responseType: 'blob', })
    }else{
        console.error('############ 未知的method ############', method)
        return false
    }
}