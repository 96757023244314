<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
if(window.location.protocol !== 'https:' && window.location.host.indexOf('localhost') < 0){
  window.location.href = window.location.href.replace('http:','https:');
}
export default {
  created () {
		// 获取登录信息
    this.$store.dispatch("user/getLoginInfo");
  },
};
</script>
<style lang="less">
// 首页产品服务下拉菜单
.home-header-submenu {
  left: 0 !important;
  width: 100vw;
  z-index: 2005 !important;
  top: 64px !important;
}
// 注册页面密码的弹窗
.register-pop.el-popper[x-placement^="bottom"] .popper__arrow {
  left: 55px !important;
}
</style>
<style lang="less">
@color: #ee2223;
#app {
  min-width: 704px;
}
</style>