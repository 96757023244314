import Vue from "vue";
import VueRouter from "vue-router";
// import productService from "../views/productService.vue";
// import Login from "../views/login.vue";
// import Home from "../views/home.vue";
// import Case from "../views/case.vue";
// import Product from "../views/product.vue";
// import Consult from "../views/consult.vue";
// import Cooperation from "../views/cooperation.vue";
// import About from "../views/about.vue";
// import ChangePwd from "../views/changepwd.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   component: productService,
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   // component: () => import(/* webpackChunkName: "about" */ "../views/home.vue"),
  // },
  { 
    path: "/", name: "Home", component:  () => import("@/views/home.vue"),
    meta: { title: '企业外呼卡_工作手机_云联络中心_5G消息-用友通信[官网] ', }
  }, 
  { 
    path: "/case/:type", name: "Case", component:  () => import("@/views/case.vue"),
    meta: { title: '案例' },
  }, 
  { 
    path: "/product/:type", name: "Product", component: () => import("@/views/product.vue"),
    meta: { title: '产品' },
  }, 
  { 
    path: "/consult", name: "Consult", component: () => import("@/views/consult.vue"),
    meta: { title: '业务咨询', }
  }, 
  { 
    path: "/cooperation", name: "Cooperation", component: () => import("@/views/cooperation.vue"),
    meta: { title: '合作伙伴', }
  }, 
  { 
    path: "/about", name: "About", component: () => import("@/views/about.vue"),
    meta: { title: '关于我们', }
  }, 
  { 
    path: "/login", name: "Login", component: () => import("@/views/login.vue"),
    meta: { title: '登录', }
  },
  { 
    path: "/register", name: "Register", component: () => import("@/views/register.vue"),
    meta: { title: '注册', }
  },
  { 
    path: "/resetpassword", name: "ResetPassword", component: () => import("@/views/resetPassword.vue"),
    meta: { title: '找回密码', }
  },
  { 
    path: "/changepwd", name: "ChangePwd", component: () => import("@/views/changepwd.vue"),
    meta: { title: '修改密码', }
  },
  { 
    path: "/enterprise-define/login", name: "enterpriseDefineLogin", component: () => import("@/views/enterprise-define/login.vue"),
    meta: { title: '登录', }
  }, 
  { 
    path: "/419/:type", name: "419Market", component: () => import("@/views/419.vue"),
    meta: { title: '', }
  }, 
  { 
    path: "/sus", name: "419Sus", component: () => import("@/views/419sus.vue"),
    meta: { title: '', }
  }, 
  { 
    path: '/bip',
    name: 'BIP',
    component: () => import('@/views/bip/index.vue'),
    meta: {title: 'CSP授权', },
    children: [
      {
        path: 'login',
        name: 'BIPLogin',
        component: () => import('@/views/bip/login.vue'),
        meta: {title: 'CSP授权'}
      },
      {
        path: 'auth',
        name: 'BIPAuth',
        component: () => import('@/views/bip/auth.vue'),
        meta: {title: 'CSP认证'}
      },
      {
        path: 'authFail',
        name: 'BIPAuthFail',
        component: () => import('@/views/bip/authFail.vue'),
        meta: {title: '认证失败'}
      },
    ]
  },
  { 
    path: "/activity", name: "activity", component: () => import("@/views/activity.vue"),
    meta: { title: '用友通信代理招募', }
  }, 
  { 
    path: "/activityvivo", name: "activityvivo", component: () => import("@/views/activityVivo.vue"),
    meta: { title: 'vivo用友电销机-智慧电销 管理有道', }
  }, 
  { 
    path: "/400", name: "400", component: () => import("@/views/400.vue"),
    meta: { title: '400服务', }
  }, 
];

// 是否需要判断未登录的时候跳转登录页

const router = new VueRouter({
  // mode: "history",
  // base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }

  window.scrollTo(0, 0);

  next()

  if (
    window.location.host !== 'dev.csp.yonyoutelecom.cn' &&
    window.location.host !== 'test.csp.yonyoutelecom.cn' &&
    window.location.host !== 'csp.yonyoutelecom.cn' &&
    window.location.host !== 'www.yonyoutelecom.cn' &&
    window.location.host !== 'yonyoutelecom.cn' &&
    window.location.host !== 'localhost:8080'
  ) {
    // 自定义界面的
    const defineRouteList = ['enterpriseDefineLogin', 'Register', 'ResetPassword', 'ChangePwd']
    if (defineRouteList.indexOf(to.name) == -1) {
      next({ name: 'enterpriseDefineLogin', replace: true })
    } else {
      next()
    }
  } else {
    next()
  }
})


export default router;
