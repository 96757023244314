// element组件
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'iview/dist/styles/iview.css'
// 默认蓝色按钮样式改为红色
import './theme/index.css'
import '@/styles/index.less' // global css
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";



Vue.use(ElementUI)
Vue.config.productionTip = false;



new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
